// Copied from CRM2 Client at 13.12.2023
import { ApiResponse } from "../publicFormApi/apiResponse";
import PropertyPathPrefixKey from "@/types/symbols/PropertyPathPrefixKey";
import ValidationErrorsKey from "@/types/symbols/ValidationErrorsKey";
import ValidationStateMapKey from "@/types/symbols/ValidationStateMapKey";
import { flatten, keyBy, keys, uniq, uniqBy } from "lodash";
import {
	computed,
	ComputedRef,
	inject,
	nextTick,
	provide,
	ref,
	Ref,
	watch,
	WritableComputedRef,
} from "vue";
import { FieldCategory, ValidationStateMap } from "./../types/interfaces/field/index";
import ValidatePropsKey from "@/types/symbols/ValidatePropsKey";
import { ValidationFailureDto } from "@api/validation-failure-dto";
import { ErrorCode } from "../../../CrmApi/TS/error-code"; // Didn't work with @api for some reason...

export const useFields = <FieldCategoryMapType>(options: {
	category?: string;
	fieldCatsComputed: ComputedRef<FieldCategory[]>;
	whiteListComputed?: ComputedRef<string[]>;
}) => {
	const fieldCategories = options.fieldCatsComputed;

	const fieldCategoriesFiltered = computed(() => {
		if (
			options.whiteListComputed &&
			options.whiteListComputed.value &&
			options.whiteListComputed.value.length > 0
		) {
			const cats = fieldCategories.value
				.filter((r) => (options.category && r.id == options.category) || !options.category)
				.map((c) => {
					const cat = { ...c };
					cat.fields = cat.fields.filter((f) =>
						options.whiteListComputed?.value.includes(f.id)
					);
					return cat;
				});

			return cats;
		}

		return fieldCategories.value.filter(
			(r) => (options.category && r.id == options.category) || !options.category
		);
	});

	const fieldCategoriesMap = computed(() => {
		return keyBy(fieldCategoriesFiltered.value, (r) => r.id) as unknown as FieldCategoryMapType;
	});

	const fieldsFlat = computed(() => {
		return flatten(fieldCategoriesFiltered.value.map((r) => r.fields));
	});

	return {
		fieldCategoriesMap,
		fieldCategoriesFiltered,
		fieldsFlat,
	};
};

export const useValidationErrors = (propertyNames: string[], matchCustomStateValue?: string) => {
	const allValidationErrors = inject(ValidationErrorsKey, null);
	const propertyPathPrefixKey = inject(PropertyPathPrefixKey, null);

	return {
		validationErrors: computed(() => {
			const scopedErrors =
				allValidationErrors?.value.filter((r) => {
					if (r.customState !== null && matchCustomStateValue !== r.customState) {
						return false;
					}
					const prefix = propertyPathPrefixKey?.value || "";
					const prefixedPropertyNames = propertyNames.map((r) => {
						const searchPath = prefix + r;
						return searchPath;
					});
					return prefixedPropertyNames.includes(r.propertyName);
				}) || [];

			//return scopedErrors;
			return uniqBy(scopedErrors, (r) => r.propertyName);
		}),
	};
};

export const useValidation = <T>() => {
	const validationErrors = ref<ValidationFailureDto[]>([]);
	provide(ValidationErrorsKey, validationErrors);

	watch(validationErrors, () => {
		validationErrors.value.forEach((e) => {
			validationStateMap.value[e.propertyName] = false;
		});
	});

	const validationStateMap = ref<{ [key: string]: boolean | null }>({});
	provide(ValidationStateMapKey, validationStateMap);

	const handleResponseValidation = async (
		resp: ApiResponse<T>,
		callbacks?: {
			success?: () => void;
			validationError?: () => void;
			otherError?: () => void;
			unhandled?: () => void;
		}
	) => {
		const validationStateKeys = keys(validationStateMap.value);
		validationStateKeys.forEach((k) => {
			validationStateMap.value[k] = true;
		});

		if (resp.isSuccessful) {
			validationErrors.value = [];
			if (callbacks?.success) {
				callbacks.success();
			}
			return true;
		} else {
			if (resp.error?.codeName == ErrorCode.ValidationError) {
				const errors = resp.error.data as ValidationFailureDto[];

				const errorProps = uniq(errors.map((r) => r.propertyName));

				const previousErrorProps = validationStateKeys.filter((k) => !errorProps.includes(k));
				previousErrorProps.forEach((p) => (validationStateMap.value[p] = true));

				validationErrors.value = errors;

				if (callbacks?.validationError) {
					callbacks.validationError();
				}
				return false;
			} else {
				if (callbacks?.otherError) {
					callbacks.otherError();
				}
			}
		}

		return undefined;
	};

	return {
		validationErrors,
		validationStateMap,
		handleResponseValidation,
	};
};

export const useLiveValidation = <T>(
	apiCall: (data: T, propertyNames: string[]) => Promise<ApiResponse<any>>,
	data: WritableComputedRef<T> | ComputedRef<T> | Ref<T>,
	validationErrors: Ref<ValidationFailureDto[]>,
	validationStateMap: Ref<ValidationStateMap>
) => {
	const validateProps = (propertyNames: string[]) => {
		if (!validationStateMap) {
			return;
		}

		if (!validationErrors) {
			return;
		}

		apiCall(data.value, propertyNames).then((r) => {
			validationErrors.value = validationErrors.value.filter(
				(r) => !propertyNames.includes(r.propertyName)
			);
			if (r.isSuccessful) {
				propertyNames.forEach((p) => {
					validationStateMap.value[p] = true;
					validationErrors.value = validationErrors.value.filter(
						(r) => !propertyNames.includes(r.propertyName)
					);
				});
			} else {
				if (r.error?.codeName == ErrorCode.ValidationError) {
					const errors = r.error.data as ValidationFailureDto[];

					validationErrors.value = uniqBy([...validationErrors.value, ...errors], (r) =>
						JSON.stringify(r)
					);

					const invalidPaths = errors.map((r) => r.propertyName);

					const validPaths = propertyNames.filter((r) => !invalidPaths.includes(r));

					invalidPaths.forEach((r) => (validationStateMap.value[r] = false));
					validPaths.forEach((r) => (validationStateMap.value[r] = true));
				}
			}
		});
	};

	provide(ValidatePropsKey, validateProps);

	return {
		validateProps,
	};
};

export const useValidateProps = () => {
	const validateProps = inject(ValidatePropsKey, () => {
		console.info("ValidateProps function not provided");
	});
	return validateProps;
};
