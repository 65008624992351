import { computed, ref } from "vue";
import { i18n } from "./i18n";
import mobile from "is-mobile";
import { LanguageDto } from "../../CrmApi/TS/language-dto";
import { MunicipalityDto } from "@api/municipality-dto";
import { FormDataDto } from "@api/form-data-dto";
import { useAppStore } from "./pinia/appStore";

export interface TranslationThing {
	languageCode: string;
	[key: string]: string;
}

export const getEntityTranslation = (
	translations: unknown | undefined,
	key: string,
	languageCode?: string
) => {
	if (typeof translations == "undefined") {
		return undefined;
	}

	const _translations: TranslationThing[] = translations as unknown as TranslationThing[];

	if (_translations.length <= 0) {
		return undefined;
	}

	let code = i18n.global.locale;
	if (languageCode) {
		code = languageCode;
	}

	let translationObj = _translations.filter((r) => r.languageCode == code).shift();

	if (!translationObj || !translationObj[key]) {
		translationObj = _translations.filter((r) => r.languageCode == "fi").shift();
	}

	if (translationObj && translationObj[key]) {
		return translationObj[key];
	}

	// return _translations;
	return "Translation missing (" + code + ")";
};

export const getLanguageTranslation = (
	language: LanguageDto | undefined | null,
	targetLanguageCode?: string
) => {
	if (language == null || typeof language == "undefined") {
		return undefined;
	}

	const l = language;
	let code = i18n.global.locale;
	if (targetLanguageCode) {
		code = targetLanguageCode;
	}

	if (code == "fi") {
		return l.finnish;
	} else if (code == "sv") {
		return l.swedish;
	} else {
		if (l.english != l.native) {
			return l.english + ", " + l.native;
		} else {
			return l.english;
		}
	}

	// switch (code) {
	// 	case "fi":
	// 		return language.finnish;
	// 	case "en":
	// 		return language.english;
	// 	// case "sv":
	// 	// 	return language.swedish;
	// 	case "native":
	// 		return language.native;
	// 	default:
	// 		return language.finnish;
	// }
};

export const getMunicipalityTranslation = (
	municipality: MunicipalityDto | undefined | null,
	targetLanguageCode?: string
) => {
	if (municipality == null || typeof municipality == "undefined") {
		return undefined;
	}

	let code = i18n.global.locale;
	if (targetLanguageCode) {
		code = targetLanguageCode;
	}

	switch (code) {
		case "fi":
			return municipality.finnish;
		default:
			if (municipality.swedish == municipality.finnish) {
				return municipality.swedish;
			} else {
				return municipality.swedish + " (" + municipality.finnish + ")";
			}
	}
};

export const getOrganizationNameTranslation = (
	formData: FormDataDto<any> | undefined | null,
	targetLanguageCode?: string
) => {
	let name = "";
	if (formData?.publicForm?.translations) {
		[targetLanguageCode, i18n.global.locale].forEach((lang) => {
			if (!name)
				name =
					formData.publicForm.translations.find((x) => x.languageCode == lang)
						?.publicOrganizationName ?? "";
		});
	}

	if (!name) {
		const org = useAppStore().organizationInfo;
		if (org) {
			[targetLanguageCode, i18n.global.locale, "fi"].forEach((lang) => {
				if (!name) name = org.translations.find((x) => x.languageCode == lang)?.name ?? "";
			});
		}
	}

	return name;
};

export const isMobile = () => {
	let isDesktop = !mobile();

	if (isDesktop && window.innerWidth <= 768) {
		isDesktop = false;
	}

	return !isDesktop;
};

const isMobileRef = ref<boolean>(isMobile());

export const isMobileReactive = computed(() => isMobileRef.value);

window.addEventListener(
	"resize",
	function () {
		isMobileRef.value = isMobile();
	},
	true
);
