import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Validation = _resolveComponent("Validation")!
  const _component_LabelControl = _resolveComponent("LabelControl")!

  return (!_ctx.noLabel)
    ? (_openBlock(), _createBlock(_component_LabelControl, {
        key: 0,
        for: _ctx.cUniqueId,
        "col-size": 2,
        block: _ctx.cBlock,
        required: 
			_ctx.required ||
			(_ctx.field &&
				(_ctx.isUpdateForm ? _ctx.field.isRequiredOnCompanyUpdateForm : _ctx.field.isRequiredOnPublicForm)) ||
			false
		,
        alignContent: _ctx.alignContent
      }, {
        label: _withCtx(() => [
          _renderSlot(_ctx.$slots, "label"),
          (!_ctx.$slots.label)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.fieldLabel) + " ", 1),
                (_ctx.isUpdateForm && _ctx.orgUsingPublicDirectory)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "show-public-icon",
                      style: _normalizeStyle(
						_ctx.field?.isDisplayedInPublicDirectory
							? 'background-color: #32b769'
							: 'background-color: gray'
					)
                    }, null, 4))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Validation, {
            fieldName: _ctx.fieldLabel || 'missing label',
            propertyPaths: _ctx.cPropertyPaths,
            matchCustomStateValue: _ctx.matchCustomStateValue
          }, {
            default: _withCtx(({ isInvalid, propertyPaths }) => [
              (_openBlock(), _createElementBlock("div", {
                key: _ctx.router.currentRoute.value.fullPath
              }, [
                _renderSlot(_ctx.$slots, "default", {
                  isInvalid: isInvalid,
                  propertyPaths: propertyPaths,
                  elementId: _ctx.cUniqueId,
                  id: _ctx.cUniqueId,
                  fieldLabel: _ctx.fieldLabel
                })
              ]))
            ]),
            _: 3
          }, 8, ["fieldName", "propertyPaths", "matchCustomStateValue"])
        ]),
        _: 3
      }, 8, ["for", "block", "required", "alignContent"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Validation, {
          fieldName: _ctx.fieldLabel || 'missing label',
          propertyPaths: _ctx.cPropertyPaths,
          matchCustomStateValue: _ctx.matchCustomStateValue
        }, {
          default: _withCtx(({ isInvalid, propertyPaths }) => [
            _renderSlot(_ctx.$slots, "default", {
              isInvalid: isInvalid,
              propertyPaths: propertyPaths,
              elementId: _ctx.cUniqueId,
              id: _ctx.cUniqueId,
              fieldLabel: _ctx.fieldLabel
            })
          ]),
          _: 3
        }, 8, ["fieldName", "propertyPaths", "matchCustomStateValue"])
      ]))
}