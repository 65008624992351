
import { getEntityTranslation, isMobileReactive } from "@/helpers";
import { Field } from "@/types/interfaces/field/index";
import { LabelControl } from "@hakosalo/hakolib";
import { defineComponent, PropType, computed, inject } from "vue";
import Validation from "./Validation.vue";
import { v4 } from "uuid";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
	props: {
		label: {
			type: String,
			required: false,
		},
		noLabel: {
			type: Boolean,
			default: false,
		},
		field: {
			type: Object as PropType<Field>,
			required: false,
		},
		id: {
			type: String,
		},
		block: {
			type: Boolean,
			required: false,
			default: undefined,
		},
		required: {
			type: Boolean,
			required: false,
			default: false,
		},
		propertyPaths: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		propertyPath: {
			type: String,
			required: false,
		},
		matchCustomStateValue: {
			type: String,
			required: false,
		},
		infoidentifier: {
			type: String,
			required: false,
		},
		alignContent: {
			type: Boolean,
		},
		labelAdditionalInfoText: {
			type: String,
			required: false,
		},
		isUpdateForm: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	setup(props) {
		const cUniqueId = computed(() => {
			return `${props.id || props.field?.id || "noFieldId"}_${v4()}`;
		});

		const cPropertyPaths = computed(() => {
			let paths = [...props.propertyPaths];

			if (props.propertyPath) {
				paths = [...paths, props.propertyPath];
			}

			return paths;
		});

		const fieldLabel = computed(() => {
			const label =
				props.label ||
				(props.field && getEntityTranslation(props.field.translations, "name")) ||
				undefined;
			return label;
		});

		const cBlock = computed(() => {
			if (props.block !== undefined) return props.block;

			return true;

			// return isMobileReactive.value;
		});

		const router = useRouter();

		const orgUsingPublicDirectory = inject("orgUsingPublicDirectory", false);

		return { cPropertyPaths, fieldLabel, cUniqueId, cBlock, router, orgUsingPublicDirectory };
	},
	components: { LabelControl, Validation },
});
