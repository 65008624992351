import { CompanyFieldCategoryDto } from "@api/company-field-category-dto";
import { FormDataDto } from "@api/form-data-dto";
import ApiRespGen, { ApiResponse } from "../apiResponse";
import publicFormApiAxios from "../publicFormApiAxios";
import { CompanyUpdateFormDataDto } from "@api/company-update-form-data-dto";
import { CompanyDto } from "@api/company-dto";

const controller = "public/publicForm/companyUpdateForm";

export default {
	companyUpdateForm: {
		get: {
			async getFormInfo(): Promise<ApiResponse<FormDataDto<CompanyFieldCategoryDto>>> {
				try {
					const response = await publicFormApiAxios.get<FormDataDto<CompanyFieldCategoryDto>>(
						`${controller}/`
					);
					return new ApiRespGen<FormDataDto<CompanyFieldCategoryDto>>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<FormDataDto<CompanyFieldCategoryDto>>().autoDetectFailure(ex);
				}
			},

			async getCompanyData(token: string): Promise<ApiResponse<CompanyUpdateFormDataDto>> {
				try {
					const response = await publicFormApiAxios.get<CompanyUpdateFormDataDto>(
						`${controller}/${token}`
					);
					return new ApiRespGen<CompanyUpdateFormDataDto>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<CompanyUpdateFormDataDto>().autoDetectFailure(ex);
				}
			},

			async fields(): Promise<ApiResponse<CompanyFieldCategoryDto[]>> {
				try {
					const response = await publicFormApiAxios.get<CompanyFieldCategoryDto[]>(
						`${controller}/Fields`
					);
					return new ApiRespGen<CompanyFieldCategoryDto[]>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<CompanyFieldCategoryDto[]>().autoDetectFailure(ex);
				}
			},

			async orgUsingCompanyDirectory(): Promise<ApiResponse<boolean>> {
				try {
					const response = await publicFormApiAxios.get<boolean>(
						`${controller}/OrgUsingCompanyDirectory`
					);
					return new ApiRespGen<boolean>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<boolean>().autoDetectFailure(ex);
				}
			},
		},
		post: {
			async default(formData: CompanyUpdateFormDataDto): Promise<ApiResponse<CompanyDto>> {
				try {
					const resp = await publicFormApiAxios.post<CompanyDto>(
						`${controller}/Save`,
						formData
					);

					return new ApiRespGen<CompanyDto>().success(resp.data);
				} catch (ex) {
					return new ApiRespGen<CompanyDto>().autoDetectFailure(ex);
				}
			},
		},
	},
};
