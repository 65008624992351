import { defineStore } from "pinia";
import publicFormApi from "@/publicFormApi";
// import { useRouter } from "vue-router";
import router from "@/router";
import { computed, ref, watch } from "vue";
import { OrganizationInfoDto } from "@api/organization-info-dto";

export const useAppStore = defineStore("app", () => {
	// state
	const organizationInfo = ref<OrganizationInfoDto | null>(null);
	const formSentSuccesfully = ref(false);

	// getters
	const organizationIdentifierFromRoute = computed(() => {
		// const router = useRouter();
		if (!router) {
			return "";
		}

		const route = router.currentRoute.value;
		if (!route) {
			return "";
		}

		const orgParam = route.params.org;
		if (Array.isArray(orgParam)) {
			return orgParam[0];
		} else if (orgParam) {
			return orgParam;
		} else {
			return null;
		}
	});

	// Watch for changes in the organization identifier
	watch(organizationIdentifierFromRoute, (organization) => {
		if (organization) {
			getOrganizationInfo(organization);
		}
	});

	// actions
	const getOrganizationInfo = async (organization: string | null = null) => {
		organizationInfo.value = null;

		publicFormApi.call.general.get.orgInfo(organization).then((r) => {
			if (r.isSuccessful && r.data) {
				organizationInfo.value = r.data;
			} else {
				console.error("Failed to get organization info", r.error);
			}
		});
	};

	return {
		// state
		organizationInfo,
		formSentSuccesfully,

		// getters
		organizationIdentifierFromRoute,

		// actions
		getOrganizationInfo,
	};
});
